.q10__ambient_image {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: var(--ambient_mobile_height);
    // position: sticky;
    // top: 0;
    // z-index: 10;

    @media (min-width: 1024px) {
        align-items: center;
        height: var(--ambient_desktop_height);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}