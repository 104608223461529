.content_error {
    .content_error_title {
        width: fit-content;
        font-family: var(--default-font);
        color: var(--text_color);

        &::after {
            content: "";
            width: 100%;
            display: block;
            border-bottom: 3px solid var(--red);
        }
    }
    .content_error_description {
        margin-top: 3rem;
    }
}
