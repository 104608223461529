.q10__footer {
    width: 100%;
    bottom: 0;
    position: relative;
    padding-top: 30px;

    .q10__footer__content {
        width: 100%;

        .q10__footer__nav {
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;
            padding: 0px 10px;

            @media (min-width: 1024px) {
                flex-direction: row;
                justify-content: center;
                padding-bottom: 6px;
            }

            .footer_logos {

                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (min-width: 1024px) {
                    width: unset;
                }

                &.border-top {
                    border-top: 1px solid #69AFAF;

                    @media (min-width: 1024px) {
                        border-top: unset;
                    }
                }

                .footer_logo {
                    width: 113px;
                    height: 32px;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                }
            }

            .footer_social_media_logos {
                gap: 10px;
                padding: 10px;
                display: flex;

                a {
                    width: 35px;
                    height: 35px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .footer_contact_and_policy {
                padding: 5px 10px 0px 10px;
                border-top: 1px solid #69AFAF;
                width: 100%;
                display: flex;
                justify-content: center;
                gap: 20px;

                @media (min-width: 1024px) {
                    padding: 0px 10px;
                    border-top: unset;
                    width: unset;
                }

                .item_parent {
                    a {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                    }

                    .arrow_right {
                        display: none;
                    }
                }
            }
        }
    }
}