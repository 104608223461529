.content_wrapper {
    .section_slider_content {
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
            gap: 50px;
            flex-direction: row;
        }

        .section_slider_description {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #000;

            @media (min-width: 1024px) {
                font-size: 16px;
                width: 50%;
            }

            &.wysiwyg {
                p {
                    margin-bottom: 20px;
                }

                @media (min-width: 1024px) {
                    p:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .section_slider_images {
        @media (min-width: 1024px) {
            width: 50%;
        }
    }
}