@import "./section_news";

.post_category {
    padding: 0 10px;
    margin: 30px auto 0;

    @media (min-width: 1024px) {
        // max-width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 994px;
    }

    &_blocks {
        width: 100%;

        @media (min-width: 1024px) {
            width: 80%;
        }
    }

    &_blocks100 {
        font-family: var(--default-font);
        width: 100%;

        .section_news {
            display: flex;
            gap: 50px;
            flex-direction: row;
            max-width: unset;
            background: unset;

            &_image {
                width: 50%;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                overflow: hidden;
                height: unset;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &_text {
                width: 50%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .q10__footer__nav {
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;

            @media (min-width: 1024px) {
                flex-direction: row;
                justify-content: center;
            }

            .footer_logos {
                border-top: 1px solid #69AFAF;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (min-width: 1024px) {
                    border-top: unset;
                    width: unset;
                }

                .footer_logo {
                    width: 113px;
                    height: 32px;

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                }
            }

            .footer_social_media_logos {
                gap: 10px;
                padding: 10px;
                display: flex;

                a {
                    width: 35px;
                    height: 35px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .footer_contact_and_policy {
                padding: 5px 10px 0px 10px;
                border-top: 1px solid #69AFAF;
                width: 100%;
                display: flex;
                justify-content: center;
                gap: 20px;

                @media (min-width: 1024px) {
                    padding: 0px 10px;
                    border-top: unset;
                    width: unset;
                }

                .item_parent {
                    a {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                    }

                    .arrow_right {
                        display: none;
                    }
                }
            }
        }
    }

    .title {
        font-size: 30px;
        font-weight: 500;
        // margin-bottom: 1rem;
    }

    .child_title {
        font-size: 20px;
        font-weight: 500;
        color: #000;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }

    .content {
        margin-left: 2rem;

        &_box {
            margin-bottom: 2rem;

            &_child {
                margin-left: 2rem;
            }
        }
    }

    &_list {
        width: 100%;

        &_title {
            margin-bottom: 1rem;
            font-size: 20px;
            font-weight: bold;
        }

        &_box {
            overflow: unset;
            width: 100%;

            &_categories {
                width: 100%;
                display: flex;
                flex-direction: column;

                &_item {
                    background: #f2f2f2;
                    padding: 1rem;

                    a {
                        text-decoration: none;
                        color: #223972;
                    }
                }
            }

            // @media (min-width: 768px) {
            //     overflow: hidden;
            //     width: 250px;
            // }
            @media (min-width: 1024px) and (max-width: 1200px) {
                overflow: hidden;
                width: 200px;
            }
        }

        @media (min-width: 1024px) {
            width: 20%;
            margin-left: 15px;
            position: sticky;
            height: 100%;
            top: 0;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 2rem;
    }

    ul {
        // list-style-type: none;

    }

    // ul li {
    //     border-bottom: 1px solid #efefef;
    //     padding: 1rem 0;
    // }

    ul ul li {
        margin: 0;
    }

    .post_category_child {
        margin-top: 2rem;
    }
}

.selected {
    border-left: 5px solid #10aedf;
}