.q10__navbar {
    width: 100%;

    .q10__navbar__free_lesson {
        background: linear-gradient(90deg, #69AFAF 0%, #AECFEE 97.07%);
        width: 100%;
        height: 54px;
        position: relative;
    
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 5%;
        }

        .item_parent {
            a {
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                padding: 10px 20px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
                text-align: center;
            }

            img {
                display: none;
            }
        }
       
    }
}