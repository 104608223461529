@font-face {
    font-family: "Poppins";
    src:
        url("../../fonts/front/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: 
        url("../../fonts/front/Poppins-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
