@import './node_modules/slick-carousel/slick/slick.scss';
@import './node_modules/slick-carousel/slick/slick-theme.scss';

// .slick-slider {
//     @media (min-width: 1024px) {
//         // padding: 0px 80px;
//     }
// }

.slick-list {
    // height: inherit;
    padding: 0 !important;
}

.slick-track {
    // display: flex;
    // height: 100%;

    @media (min-width: 1024px) {
        padding-top: 10px;
    }
}

.slick-slide {
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
        padding: 0 5px;
        width: 984px !important;
        // height: auto;
    }

    &>div {
        height: 100%;
    }
}


// .slick-prev,
// .slick-next {
//     box-sizing: border-box;
//     cursor: pointer;
//     transition: transform 150ms linear;
//     position: absolute;
//     width: 48px;
//     height: 48px;
// }

// .slick-prev.hero_btn {
//     // left: 0;
//     // z-index: 1;
//     display: block;
//     z-index: 5;
//     position: absolute;
//     left: 10%;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 24px;
//     height: 24px;
// }

// .slick-next {
//     right: 20px;
// }

// .slick-prev:before,
// .slick-next:before {
//     content: '';
//     width: 100%;
//     height: 100%;
//     display: block;
// }