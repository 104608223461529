.image_fullscreen {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.95);

	.max-size {
		max-width: 90%;
		max-height: 90%;
		width: unset;
		height: unset;
	}

	.image_fullscreen_close {
		position: absolute;
		z-index: 1;
		right: 1rem;
		top: 1rem;
		border: none;
		background: transparent;
		outline: none;
		font-size: 35px;
		line-height: 35px;
		cursor: pointer;
	}

	.image_fullscreen_container {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;

		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 15px;

		.arrow_navigation {
			width: 48px;
			height: 48px;
			margin: 0 15px;
			padding: 0;
			border: none;
			background: transparent;
			color: transparent;
			opacity: 0.8;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}

			&:disabled {
				opacity: 0.25;
			}

			&.arrow_nav_prev {
				background: url(/images/angle_left_slider.svg) center/100% no-repeat;
			}

			&.arrow_nav_next {
				background: url(/images/angle_right_slider.svg) center/100% no-repeat;
			}
		}

		.slider_content {
			flex-grow: 1;
			height: 100%;

			.section_slider {
				position: relative;
				height: 100%;
				width: 100%;

				.section_slide {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		&.single_image {

			.slider_content {
				.section_slider {
					min-height: unset;

					.section_slide {
						// position: static;
					}
				}
			}
		}
	}
}