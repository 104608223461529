// @import "~bootstrap/scss/bootstrap";

// @import "variables";
@import "_variables";
@import "error_404";
@import "hero";
@import "slick_carousel";
@import "fonts";
@import "header";
@import "footer";
@import "navbar";
@import "ambient";
@import "post_category";
@import "section_news";
@import "vue_elements";
@import "section_slider";

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition-property: background-color, color;
    transition-duration: 0.3s;
}

html {
    scroll-behavior: smooth;
    height: 100vh;
}

#app {
    background-color: var(--primary_color);
    min-height: 100vh;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 6px 0;
    color: #676767;
    // overflow-x: hidden;
}

.background-app {
    background-color: white !important;

}

.single_block {
    &> :first-child {
        min-height: calc(100vh - var(--footer_height) - var(--header_height));
    }

    &>* {
        img {
            width: 100%;
        }
    }
}

.q10__content {
    position: relative;
    margin: auto;
    width: 100%;
    // max-width: 1320px;
    // max-width: 994px;
    margin-top: 0;
    // padding: 0 10px;

    @media (min-width: 1024px) {
        // padding: 0 40px;
        // margin-top: var(--header_desktop_height);
    }

    @media (min-width: 1366px) {
        padding: 0;
    }

    &.with_ambient {
        // margin-top: 0;
        // Pentru sectiunile cu ambient scoatem padding-bottom pentru a calcula corect distanta dintre footer si sectiune
    }

    // & > :first-child {
    //     min-height: calc(100vh - var(--footer_height) - var(--header_height));
    // }

}

.q10__section {

    &>*:not(.hp_slider) {
        margin: 30px auto 0 auto;
        padding: 0 10px;
    }

    @media (min-width: 1024px) {
        &>.content_wrapper {
            max-width: 994px;
            // margin: 0 auto;
        }
    }
}

.breadcrumbs+.q10__section {
    padding-top: 0;

    @media (min-width: 1024px) {
        padding-top: 0;
    }

}

// a:hover{
//     color:#6f95f7
// }
a {
    text-decoration: none;
}

p {
    @media (min-width:1024px) {
        font-size: 18px;
    }
}


.pagination_image_fullscreen {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.95);


    .max-size {
        max-width: 90%;
        max-height: 90%;
        width: unset;
        height: unset;
    }

    // img {
    //     max-width: 90%;
    //     max-height: 90%;
    //     height: auto;
    //     display: block;
    //     object-fit: contain;
    // }

    button {
        outline: none;
        border: none;
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 35px;
        background: transparent;
        line-height: 35px;
        cursor: pointer;
    }
}


.wysiwyg {
    p {
        margin-bottom: 1rem;
    }

    ul {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
}